import React from 'react';
import { Box, Flex, Image } from 'rebass';

function LinkPreview({ linkPreview }: Props) {
  return (
    <Flex
      css={{
        border: '1px solid #dedede',
      }}
      p={2}
    >
      <Flex
        height={120}
        width={120}
        mr={2}
        css={{
          flexShrink: '0',
        }}
      >
        <Image
          height={120}
          width={120}
          src={linkPreview.imageUrl}
          css={{
            objectFit: 'cover',
          }}
        />
      </Flex>
      <Flex
        flexDirection="column"
        css={{
          overflow: 'hidden',
        }}
      >
        <Box
          css={{
            fontFamily: 'Georgia, serif',
            fontSize: '18px',
            fontWeight: '500',
            color: '#1d2129',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          mb={1}
        >
          {linkPreview.title}
        </Box>
        <Box
          css={{
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontSize: '12px',
            color: '#1d2129',
          }}
        >
          {linkPreview.description}
        </Box>
        <Box
          mt={2}
          css={{
            textTransform: 'uppercase',
            color: '#90949c',
            fontSize: '11px',
          }}
        >
          {linkPreview.canonicalUrl}
        </Box>
      </Flex>
    </Flex>
  );
}

export default LinkPreview;
