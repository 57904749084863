// @flow

import type { Post_post_attachments } from '../../../shared/graphql.flow';

import * as React from 'react';
import { Box, Flex } from 'rebass';
import { Absolute, Relative } from '@rebass/position';

import Image from '../Image';

type Props = {
  attachments: Post_post_attachments,
  className?: string,
  count: number,
  onSelect?: (event: SyntheticEvent<HTMLDivElement>) => void,
  postId: string,
};

function PostPhotosGroup(props: Props) {
  const { count } = props;
  const attachments = props.attachments.slice(0, 3);

  return (
    <Relative onClick={window.__isTouch ? props.onSelect : undefined}>
      <Box
        bg="#eee"
        p={1}
        css={{
          borderRadius: '2px',
          display: 'grid',
          gridColumnGap: '4px',
          gridTemplateColumns: `repeat(${count > 3 ? 3 : count}, 1fr)`,
        }}
      >
        {attachments.map((attachment, index) => (
          <Box
            key={attachment.node.id}
            css={{ border: '1px solid rgba(0, 0, 0, 0.065)' }}
          >
            <Image
              alt={attachment.node.name}
              fit="contain"
              size={240}
              src={
                attachment.node.type === 'video'
                  ? `${attachment.node.transcodeUrls[0]}`
                  : `${attachment.node.media.image.src}`
              }
            />
          </Box>
        ))}
      </Box>

      {count > 3 && (
        <Absolute bottom={4} right={4} top={4} width="calc(33.33% - 5px)">
          <Flex
            alignItems="center"
            bg="rgba(0, 0, 0, 0.4)"
            color="white"
            fontSize={34}
            justifyContent="center"
            css={{ height: '100%' }}
          >
            +{count - 3}
          </Flex>
        </Absolute>
      )}

      {!window.__isTouch && (
        <Absolute
          bottom={2}
          left={2}
          right={2}
          top={2}
          css={{ opacity: 0, '&:hover': { opacity: 1 } }}
        >
          <Flex
            alignItems="center"
            bg="rgba(0, 0, 0, 0.5)"
            color="white"
            fontSize={34}
            justifyContent="center"
            css={{ height: '100%' }}
          >
            <button className="btn btn-white" onClick={props.onSelect}>
              Options
            </button>
          </Flex>
        </Absolute>
      )}
    </Relative>
  );
}

export default PostPhotosGroup;
