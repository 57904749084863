// @flow

import * as React from 'react';
import Media from 'react-media';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';

import logo from './logo.png';
import { Wrapper } from './styles';

class Header extends React.PureComponent<{}, { collapsed: boolean }> {
  state = {
    collapsed: true,
  };

  toggleNavbar = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    return (
      <Wrapper>
        <Media query={{ minWidth: 768 }}>
          {matches => (
            <Navbar light color="faded" expand="md" style={{ padding: 0 }}>
              <img alt="Sharelov" height={48} src={logo} />
              {!matches && (
                <NavbarToggler
                  aria-label="Toggle navigation"
                  onClick={this.toggleNavbar}
                />
              )}
              <Collapse navbar isOpen={!this.state.collapsed}>
                <Nav navbar style={{ marginLeft: 'auto', marginTop: 0 }}>
                  <NavItem>
                    <NavLink
                      className={matches ? 'btn btn-primary-sh' : ''}
                      href="https://www.sharelov.com"
                    >
                      About Sharelov
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown inNavbar nav className="ml-md-3">
                    <DropdownToggle
                      caret
                      nav
                      className={matches ? 'btn btn-primary-sh' : ''}
                    >
                      Solutions
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem href="https://www.sharelov.com/solutions/agencies">
                        Sharelov for Agencies
                      </DropdownItem>
                      <DropdownItem href="https://www.sharelov.com/solutions/marketing-teams">
                        Sharelov for Marketers
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem className="ml-md-3">
                    <NavLink
                      className={matches ? 'btn btn-pink' : ''}
                      href="https://app.sharelov.com/signup"
                    >
                      Start for Free
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          )}
        </Media>
      </Wrapper>
    );
  }
}

export default Header;
