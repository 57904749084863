// @flow

import * as React from 'react';
import { HotKeys } from 'react-hotkeys';
import { Portal } from 'react-portal';
import Transition from 'react-transition-group/Transition';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PersistScroll from '../PersistScroll';

import {
  Body as StyledBody,
  Close,
  Container,
  Overlay,
  Panel as StyledPanel,
  ScrollArea,
  Title,
} from './styles';

type Props = {
  children: React.Node,
  onToggle?: () => void,
};

type State = {
  show: boolean,
};

const overlayTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
};

const panelTransitionStyles = {
  entering: { right: -300 },
  entered: { right: 0 },
};

class Panel extends React.PureComponent<Props, State> {
  static Header = Title;
  static Body = StyledBody;

  container: HTMLDivElement;

  state = {
    show: true,
  };

  onClick = () => {
    this.setState(({ show }) => ({ show: !show }));
  };

  componentDidMount() {
    this.container.focus();
  }

  render() {
    return (
      <Portal>
        <PersistScroll>
          <Transition
            appear
            onExited={this.props.onToggle}
            in={this.state.show}
            timeout={{ enter: 0, exit: 500 }}
          >
            {state => (
              <HotKeys
                handlers={{ close: this.onClick }}
                keyMap={{ close: 'esc' }}
              >
                <Container ref={node => (this.container = node)}>
                  <Overlay
                    onClick={this.onClick}
                    style={overlayTransitionStyles[state]}
                  />
                  <StyledPanel style={panelTransitionStyles[state]}>
                    <Close onClick={this.onClick}>
                      <FontAwesomeIcon icon="times-circle" size="lg" />
                    </Close>
                    <ScrollArea>{this.props.children}</ScrollArea>
                  </StyledPanel>
                </Container>
              </HotKeys>
            )}
          </Transition>
        </PersistScroll>
      </Portal>
    );
  }
}

export default Panel;
