import styled from 'styled-components';

export const Container = styled('div')`
  height: auto;
  min-height: 100%;

  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  outline: none;
  z-index: 400;
`;

export const Overlay = styled('div')`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  background: rgba(0, 0, 0, 0.4);
  opacity: 0;

  transition: opacity 400ms ease-in-out;
`;

export const Panel = styled('div')`
  width: 300px;

  bottom: 0;
  position: fixed;
  right: -300px;
  top: 0px;

  background-color: #808080;

  transition: right 400ms ease-in-out;
`;

export const Close = styled('button')`
  border: none;
  padding: 0;

  left: -10px;
  position: absolute;
  top: 20px;

  background: none;
  color: #fff;
  cursor: pointer;
`;

export const ScrollArea = styled('div')`
  height: 100%;

  overflow: auto;
`;

export const Title = styled('h2')`
  line-height: 1;
  margin: 20px 0;

  font-size: 24px;
  font-weight: 200;

  color: #fff;
  text-align: center;
`;

export const Body = styled('div')`
  padding: 0 10px 20px;

  color: #fff;
`;
