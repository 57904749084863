// @flow

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Icon } from './styles';

type Props = {
  height: number,
  radius: any,
  type: string,
  width: number,
};

const COLORS_MAP = {
  CREATIVE_ASSETS: '#60c4bc',
  FACEBOOK_ADS: '#39579d',
  GOOGLE_ADWORDS: '#f06765',
  INSTAPOSTS:
    'linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00)',
  LIBRARY: '#60c4bc',
  LINKEDIN: '#027cb6',
  POSTS: '#39579d',
  TWEETS: '#67aadc',
  WORDS: '#F4A5A9',
  YOUTUBE: 'red',
};

const ICONS_MAP = {
  FACEBOOK_ADS: ['fa', 'bullhorn'],
  GOOGLE_ADWORDS: ['fab', 'google'],
  INSTAPOSTS: ['fab', 'instagram'],
  LIBRARY: ['fal', 'book-heart'],
  LINKEDIN: ['fab', 'linkedin-in'],
  POSTS: ['fab', 'facebook-f'],
  TWEETS: ['fab', 'twitter'],
  WORDS: ['fal', 'file-alt'],
  YOUTUBE: ['fab', 'youtube'],
};

class SocialMediaIcon extends React.PureComponent<Props> {
  background() {
    return COLORS_MAP[this.props.type];
  }

  borderRadius() {
    let radius = this.props.radius;

    if (typeof radius === 'number') {
      radius = `${radius}px`;
    }

    return radius;
  }

  icon() {
    return ICONS_MAP[this.props.type];
  }

  render() {
    const percentage = this.props.type === 'CREATIVE_ASSETS' ? 55 : 65;
    const fontSize =
      (Math.min(this.props.height, this.props.width) / 100) * percentage;

    const styles = {
      background: this.background(),
      borderRadius: this.borderRadius(),
      fontSize: `${fontSize}px`,
      height: `${this.props.height}px`,
      width: `${this.props.width}px`,
    };

    return (
      <Icon style={styles}>
        {this.props.type === 'CREATIVE_ASSETS' ? (
          <span>CC</span>
        ) : (
          <FontAwesomeIcon icon={this.icon()} size="sm" />
        )}
      </Icon>
    );
  }
}

export default SocialMediaIcon;
