// @flow

import type { Post_post } from '../../../shared/graphql.flow';

import * as React from 'react';

import format from 'date-fns/format';
import delve from 'dlv';
import ContentLoaderBase from 'react-content-loader';
import Waypoint from 'react-waypoint';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { Fixed } from '@rebass/position';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonPrimary, ButtonYellow } from '../buttons';
import { Icon } from './styles';
import copyToClipboard from '../../utils/copyToClipboard';
import FacebookAdsContainer from '../FacebookAdsContainer';
import GoogleAd from '../GoogleAd';
import Image from '../Image';
import LinkPreview from '../LinkPreview';
import PostAttachmentsContainer from '../PostAttachmentsContainer';
import PostImagesPanel from '../PostImagesPanel';
import SocialMediaIcon from '../SocialMediaIcon';
import TiptapEditor from '../TiptapEditor';

type Props = {
  className?: string,
  post: Post_post,
};

type State = {
  downloadingFilesToShare: boolean,
  editorState: EditorState,
  inViewport: boolean,
  openPanel: boolean,
  showCopyMessage: boolean,
};

const GoogleAdWrapped = styled(GoogleAd)`
  margin-top: 20px;
`;

class Post extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { post } = props;

    this.state = {
      downloadingFilesToShare: false,
      editorState: JSON.parse(post.content.body),
      inViewport: false,
      openPanel: false,
      showCopyMessage: false,
    };
  }

  copyToClipboard = () => {
    copyToClipboard(this.props.post.body);

    this.setState({ showCopyMessage: true });

    setTimeout(() => {
      this.setState({ showCopyMessage: false });
    }, 3000);
  };

  getImage() {
    const { account, brand } = this.props.post.campaign;

    return brand.avatar
      ? brand.avatar.media.image.src
      : account && account.avatarUrl
        ? account.avatarUrl
        : null;
  }

  getTitle() {
    const { account, brand } = this.props.post.campaign;

    return account ? account.name : brand.name;
  }

  onEnter = () => {
    if (this.state.inViewport) {
      return;
    }

    this.setState({
      inViewport: true,
    });
  };

  onTogglePanel = () => {
    this.setState(({ openPanel }) => ({
      openPanel: !openPanel,
    }));
  };

  shareFiles = async event => {
    event.persist();

    const attachments = delve(this.props.post, 'attachments.edges', []).map(
      edge => edge.node
    );

    this.setState({
      downloadingFilesToShare: true,
    });

    const files = await Promise.all(
      attachments.map(attachment =>
        this.downloadFile(attachment.downloadUrl, attachment.name)
      )
    );

    this.setState({
      downloadingFilesToShare: false,
    });

    this.share(files);
  };

  downloadFile = async (url, name) => {
    const resp = await fetch(url);
    const blob = await resp.blob();

    return new File([blob], name, {
      lastModified: new Date(),
      type: blob.type,
    });
  };

  share = files => {
    if (navigator.canShare && navigator.canShare({ files })) {
      navigator
        .share({
          files,
          text: ``,
          title: `Post Attachments`,
        })
        .then(() => console.log('Share was successful.'))
        .catch(error => alert(error));
    } else {
      alert(`Your system doesn't support sharing files.`);
    }
  };

  render() {
    const { post } = this.props;
    const datetime = new Date(`${post.date} ${post.time}`);
    const imgSrc = this.getImage();
    const attachments = delve(post, 'attachments.edges', []).map(
      edge => edge.node
    );
    const types = attachments.map(attachment => attachment.type);
    const showMessageError =
      post.type.toLowerCase() !== 'instaposts' &&
      post.type.toLowerCase() !== 'posts' &&
      (types.filter(t => t === 'video').length > 1 ||
        (types.includes('image') && types.includes('video')));

    let downloadUrl = post.zipballUrl;
    if (attachments.length === 1) {
      downloadUrl = attachments[0].downloadUrl;
    }

    return (
      <Waypoint bottomOffset={90} onEnter={this.onEnter}>
        {this.state.inViewport ? (
          <Box
            bg="white"
            className={this.props.className}
            pb={32}
            pt={20}
            px={20}
          >
            <Flex alignItems="center">
              <Box
                mr={2}
                css={{
                  borderRadius: '50%',
                  overflow: 'hidden',
                }}
              >
                {imgSrc ? (
                  <Image alt="Account" src={imgSrc} size={40} />
                ) : (
                  <img
                    alt="Account"
                    height={40}
                    src={require('./placeholder.png')}
                    width={40}
                  />
                )}
              </Box>

              <Box flex="auto">
                <Text color="primary" fontSize={1}>
                  {this.getTitle()}
                </Text>
                <Text
                  as="time"
                  color="#6b6b6c"
                  dateTime={datetime}
                  fontSize={0}
                >
                  <b>{format(datetime, 'cccc')}</b>,{' '}
                  {format(datetime, 'MMMM d, yyyy')}
                  {format(datetime, ' @ h:mm a')}
                </Text>
              </Box>

              <SocialMediaIcon
                height={32}
                width={32}
                type={post.type}
                radius={'50%'}
              />
            </Flex>

            <TiptapEditor content={this.state.editorState} />

            {post.linkPreview && <LinkPreview linkPreview={post.linkPreview} />}

            {post.attachments &&
              post.attachments.edges.length > 0 && (
                <PostAttachmentsContainer
                  attachments={post.attachments.edges}
                  onPhotoSelect={this.onTogglePanel}
                  postId={post.id}
                />
              )}

            {post.facebookAds &&
              post.facebookAds.edges && (
                <FacebookAdsContainer
                  ads={post.facebookAds.edges}
                  postId={post.id}
                />
              )}

            {post.googleAd && <GoogleAdWrapped ad={post.googleAd} />}

            {showMessageError && (
              <Flex
                style={{
                  margin: '10px 0px',
                  fontSize: '12px',
                  color: '#ee8085',
                }}
              >
                Oops! Playback for stories with multiple videos or mixed media
                is only available through the Sharelov app.
              </Flex>
            )}

            <Flex
              justifyContent="flex-end"
              mt={20}
              css={{
                textAlign: `center`,
              }}
            >
              <ButtonYellow onClick={this.copyToClipboard} type="button">
                Copy Caption
              </ButtonYellow>

              {window.__isTouch &&
                navigator.canShare &&
                attachments.length > 0 && (
                  <ButtonPrimary
                    disabled={this.state.downloadingFilesToShare}
                    onClick={this.shareFiles}
                    style={{
                      marginLeft: 16,
                    }}
                  >
                    {this.state.downloadingFilesToShare
                      ? `Downloading...`
                      : `Share Files`}
                  </ButtonPrimary>
                )}

              {downloadUrl && (
                <ButtonPrimary
                  as="a"
                  href={downloadUrl}
                  target="_blank"
                  style={{
                    marginLeft: 16,
                  }}
                >
                  Download Media
                </ButtonPrimary>
              )}
            </Flex>

            {this.state.openPanel && (
              <PostImagesPanel
                attachments={post.attachments.edges}
                onToggle={this.onTogglePanel}
                postId={post.id}
              />
            )}

            {this.state.showCopyMessage && (
              <Fixed
                bg="#fff"
                bottom={8}
                boxShadow="small"
                color="#58595b"
                display={'flex'}
                fontSize={14}
                fontWeight={300}
                ml={3}
                p={0}
                right={16}
                style={{
                  borderRadius: 5,
                  ...(window.__isTouch && { left: 0 }),
                  boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Icon>
                  <FontAwesomeIcon icon="check" size="sm" />
                </Icon>
                <div style={{ display: 'inline-block', padding: 10 }}>
                  Post caption copied!
                </div>
              </Fixed>
            )}
          </Box>
        ) : (
          <div style={{ padding: 20 }}>
            <ContentLoaderBase height={130}>
              <rect x="0" y="0" rx="4" ry="4" width="29.301" height="29.301" />

              <rect x="36.63" y="3" rx="4" ry="4" width="100" height="10.26" />
              <rect
                x="36.63"
                y="17.511"
                rx="4"
                ry="4"
                width="220"
                height="8.79"
              />

              <rect x="0" y="36.63" rx="5" ry="5" width="400" height="90" />
            </ContentLoaderBase>
          </div>
        )}
      </Waypoint>
    );
  }
}

export default Post;
