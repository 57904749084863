// @flow

import * as React from 'react';
import { generateHTML } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import styled from 'styled-components';

import { Color } from '@tiptap/extension-color';
import EmojiExtension from './Extensions/extesion-emoji';
import HashtagExtension from './Extensions/extension-hashtag';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Mention from '@tiptap/extension-mention';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';

type Props = {
  content: JSON,
};

const Body = styled('div')`
  margin-top: 10px;
  font-weight: 300;
  color: #58595b;
  font-size: 13px;

  & p {
    margin: 0 0 8px;
  }

  & strong {
    font-weight: bold;
  }

  & .hashtag {
    color: #54a89f;
  }

  & img {
    max-width: 100%;
  }
`;

class TiptapEditor extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.parentHtml = React.createRef();

    this.state = {
      data: generateHTML(props.content, [
        StarterKit,
        Color,
        Mention,
        EmojiExtension,
        HashtagExtension.configure({
          HTMLAttributes: {
            class: 'hashtag',
          },
        }),
        Highlight.configure({ multicolor: true }),
        Image,
        Link.configure({
          openOnClick: false,
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TextStyle,
        Underline,
      ]),
    };
  }

  componentDidMount() {
    const data = this.parentHtml.current;

    if (!data) {
      return;
    }

    data.innerHTML = this.state.data;
  }

  render() {
    return <Body ref={this.parentHtml} />;
  }
}

export default TiptapEditor;
