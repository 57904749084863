// @flow

import * as React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../loading';

export default Loadable({
  loader: () => import('./container' /* webpackChunkName: "media-players" */),
  loading: () => <Loading />,
});
