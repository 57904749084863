import styled from 'styled-components';

const Button = styled.button`
  align-items: center;
  border: none;
  display: flex;
  height: 44px;
  padding: 0 16px;

  font-size: 14px;

  border-radius: 6px;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
`;

export const ButtonPrimary = styled(Button)`
  color: #65c6bd;
  background-color: #daf1ef;

  &:hover {
    color: #65c6bd;
    background-color: #b5e3df;
  }
`;

export const ButtonYellow = styled(Button)`
  color: #e8a61f;
  background-color: #fceed8;

  &:hover {
    color: #e8a61f;
    background-color: #f8d9a9;
  }
`;

export const ButtonLightRed = styled(Button)`
  color: #e0645d;
  background-color: #f6c9c7;
  position: absolute;
  right: 20px;

  &:hover {
    color: #e0645d;
    background-color: #ef9f9b;
  }
`;
