// @flow

import gql from 'graphql-tag';

export default gql`
  fragment GoogleAd_ad on GoogleAd {
    description1
    description2
    headline1
    headline2
    headline3
    path1
    path2
    url
  }
`;
