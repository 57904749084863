// @flow

import styled from 'styled-components';

export const Wrapper = styled('div')`
  margin-bottom: 20px;

  background-color: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  overflow: hidden;

  text-align: right;
`;
