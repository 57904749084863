// @flow

import type { Post_post_attachments_edges } from '../../../shared/graphql.flow';

import * as React from 'react';
import { connect } from 'react-redux';

import { ButtonPrimary } from '../buttons';
import ExpandableContainer from '../ExpandableContainer';
import Image from '../Image';
import Panel from '../Panel';

import { openGallery } from '../../actions/gallery';
import { Wrapper } from './styles';

type Props = {
  attachments: Array<?Post_post_attachments_edges>,
  onToggle: () => void,
  openGallery: typeof openGallery,
  postId: string,
};

function PostImagesPanel({
  attachments,
  onToggle,
  openGallery,
  postId,
}: Props) {
  const isPdf = attachments.some(attachment =>
    attachment.node.name.endsWith('pdf')
  );

  return (
    <Panel onToggle={onToggle}>
      <Panel.Header>{isPdf ? 'PDFs' : 'Files'}</Panel.Header>
      <Panel.Body>
        {attachments.map(({ node: attachment }) => {
          const { image } = attachment.media;

          let iconType = 'image';

          if (attachment.type === 'video') {
            iconType = 'video';
          }

          if (attachment.type === 'pdf') {
            iconType = 'file-pdf';
          }

          return (
            <Wrapper key={attachment.id}>
              <ExpandableContainer
                iconType={iconType}
                onClick={() => {
                  onToggle();
                  openGallery(postId, attachment.id);
                }}
              >
                <Image
                  alt={attachment.name}
                  height={(image.height / image.width) * 280}
                  src={
                    attachment.type === 'video'
                      ? `${attachment.transcodeUrls[0]}`
                      : `${image.src}`
                  }
                  width={280}
                />
              </ExpandableContainer>

              <ButtonPrimary
                as="a"
                href={attachment.downloadUrl}
                m={1}
                target="_blank"
              >
                Download
              </ButtonPrimary>
            </Wrapper>
          );
        })}
      </Panel.Body>
    </Panel>
  );
}

export default connect(
  null,
  { openGallery }
)(PostImagesPanel);
