// @flow

import Uri from 'jsuri';

// @see http://glide.thephpleague.com/1.0/api/quick-reference
var PARAM_EXPANSION = Object.freeze({
  // Adjustment
  brightness: 'bri',
  contrast: 'con',
  gamma: 'gam',
  sharpness: 'sharp',

  // Background
  'background-color': 'bg',

  // Border & Padding
  border: 'border',

  // Format
  format: 'fm',
  quality: 'q',

  // Rotation
  orientation: 'or',

  // Size
  'crop-mode': 'crop',
  'fit-mode': 'fit',
  'image-height': 'h',
  'image-width': 'w',

  // Stylize
  blurring: 'blur',
  pixelate: 'pixel',
  px: 'pixel',

  // Extra
  height: 'h',
  width: 'w',
});

var DEFAULT_OPTIONS = Object.freeze({
  dpr: 1,
});

function constructUrl(src: string, params: Object): string {
  const optionKeys = Object.keys(params);
  const fullUrl = optionKeys.reduce((uri: Uri, key: string) => {
    return uri.addQueryParam(key, params[key]);
  }, new Uri(src));

  return fullUrl.toString();
}

/**
 * Construct a URL for an image.
 *
 * @param  {String} src         src of raw image
 * @param  {Object} longOptions map of image API options, in long or short form per expansion rules
 *
 * @return {String}             URL of image src transformed
 */
function processImage(src: string, longOptions?: Object = {}): string {
  if (!src) {
    return '';
  }

  const shortOptions = Object.assign({}, DEFAULT_OPTIONS);

  Object.keys(longOptions).forEach(key => {
    const val = longOptions[key];

    if (PARAM_EXPANSION[key]) {
      key = PARAM_EXPANSION[key];
    }

    key = encodeURIComponent(key);

    shortOptions[key] = val;
  });

  return constructUrl(src, shortOptions);
}

export default processImage;
