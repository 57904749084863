import { Node, mergeAttributes, nodeInputRule } from '@tiptap/core';

export const inputRegex = /(#[A-Za-z0-9]*)\s$/gim;

export const Hashtag = Node.create({
  name: 'hashtag',

  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ node }) {
        return `${node.attrs.hashtag}`;
      },
    };
  },

  group: 'inline',

  inline: true,

  selectable: false,

  draggable: false,

  atom: true,

  addAttributes() {
    return {
      hashtag: {
        default: null,
        parseHTML: element => {
          return element.getAttribute('data-hashtag');
        },
        renderHTML: attributes => {
          if (!attributes.hashtag) {
            return {};
          }

          return {
            'data-hashtag': attributes.hashtag,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-hashtag]',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-hashtag': '' },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ];
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: match => {
          return { hashtag: match[1] };
        },
      }),
    ];
  },
});
