export default text => {
  let textArea = document.createElement('textArea');
  textArea.readOnly = true;
  textArea.value = text;

  document.body.appendChild(textArea);

  if (navigator.userAgent.match(/ipad|iphone/i)) {
    let range = document.createRange();
    range.selectNodeContents(textArea);

    let selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }

  let success = false;

  try {
    success = document.execCommand('copy');
  } catch (err) {
    console.warn('Error copying: ', err);
  } finally {
    document.body.removeChild(textArea);
  }

  return success;
};
