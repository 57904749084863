import { Node, mergeAttributes } from '@tiptap/core';

export const Emoji = Node.create({
  name: 'emoji',

  addOptions() {
    return {
      renderLabel({ node }) {
        return `${node.attrs.emoji}`;
      },
    };
  },

  addCommands() {
    return {
      setEmoji: emoji => ({ commands }) => {
        return commands.insertContent([
          {
            type: this.name,
            attrs: {
              emoji: emoji.native,
              colons: emoji.colons,
              id: emoji.id,
            },
          },
        ]);
      },
    };
  },

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => {
          return {
            'data-id': attributes.id,
          };
        },
      },
      emoji: {
        default: null,
        parseHTML: element => element.getAttribute('data-emoji'),
        renderHTML: attributes => {
          return {
            'data-emoji': attributes.emoji,
          };
        },
      },
      colons: {
        default: null,
        parseHTML: element => element.getAttribute('data-colons'),
        renderHTML: attributes => {
          return {
            'data-colons': attributes.colons,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-emoji]',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ];
  },
});
