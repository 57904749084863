// @flow

export function openShortnerGallery(
  entityId: string,
  mediaId: string,
  attachments: array
) {
  return {
    entityId,
    mediaId,
    attachments,
    type: 'SHOW_SHORTNER_GALLERY',
  };
}

export function hideShortnerGallery() {
  return {
    type: 'HIDE_SHORTNER_GALLERY',
  };
}
