// @flow

import * as React from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import { LoadingGallery } from '../loading';

type Props = {
  entityId: string,
  isOpen: boolean,
  mediaId: string,
};

const Browser = Loadable({
  loader: () => import('./browser' /* webpackChunkName: "gallery-browser" */),
  loading: () => <LoadingGallery />,
});

function Gallery(props: Props) {
  if (props.isOpen) {
    return <Browser entityId={props.entityId} mediaId={props.mediaId} />;
  }

  return null;
}

const mapStateToProps = state => ({
  entityId: state.gallery.entityId,
  isOpen: state.gallery.isOpen,
  mediaId: state.gallery.mediaId,
});

export default connect(mapStateToProps)(Gallery);
