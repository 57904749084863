// @flow

import * as React from 'react';
import Loadable from 'react-loadable';

import { Loading } from '../loading';

/* prettier-ignore */
export default Loadable({
  loader: () => import('./container' /* webpackChunkName: "facebook-ads-container" */),
  loading: () => <Loading />,
});
