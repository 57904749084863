// @flow

import * as React from 'react';
import IdealImage from 'react-ideal-image';
import defaultTheme from 'react-ideal-image/dist/components/theme';

import processImage from './support';

type Props = {
  alt: string,
  className?: string,
  fit?: string,
  height?: number,
  onClick?: (event: SyntheticEvent<HTMLImageElement>) => void,
  onLoad?: (event: SyntheticEvent<HTMLImageElement>) => void,
  size?: number,
  src: string,
  width?: number,
};

type Dimensions = {
  height: number,
  width: number,
};

const theme = {
  ...defaultTheme,
  img: {
    height: '100%',
    maxWidth: '100%',
    objectFit: 'cover',
  },

  placeholder: {
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  },
};

class Image extends React.PureComponent<Props> {
  static defaultProps = {
    fit: 'cover',
  };

  getLqip({ height, width }: Dimensions) {
    const { fit, src } = this.props;

    return processImage(src, {
      fit,
      height,
      width,
      blur: 10,
      pixel: 16,
    });
  }

  getSrcSet({ height, width }: Dimensions): { src: string, width: number }[] {
    const { fit, src } = this.props;

    return [...new Array(3)].map((_, i) => ({
      src: processImage(src, { fit, height, width, dpr: i + 1 }),
      width: width * (i + 1),
    }));
  }

  render() {
    const { alt, className, height, onClick, size, width } = this.props;

    const dimensions = {
      height: height || size,
      width: width || size,
    };

    return (
      <IdealImage
        alt={alt}
        className={className}
        height={dimensions.height}
        loader="image"
        onClick={onClick}
        placeholder={{ lqip: this.getLqip(dimensions) }}
        shouldAutoDownload={() => true}
        srcSet={this.getSrcSet(dimensions)}
        theme={theme}
        width={dimensions.width}
      />
    );
  }
}

export default Image;
