import styled, { createGlobalStyle } from 'styled-components';

import background from './background.svg';
import backgroundIcons from './background-icons.svg';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    background-color: #c9e9e6 !important;
  }

  #sharelov .accessible_elem {
    height: 1px;
    width: 1px;

    position: absolute;

    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    white-space: nowrap;
  }

  a {
    text-decoration: none;
  }
`;

export const Page = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 15px;
  margin-right: 15px;
  max-height: 100vh;

  @media (min-width: 768px) {
    margin-left: 50px;
    margin-right: 50px;
  }
`;

export const Background = styled('div')`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  background-image: url(${backgroundIcons}), url(${background});
  background-position: right bottom;
  background-repeat: no-repeat;
  z-index: -1;
`;

export const Main = styled('div')`
  margin-bottom: 40px;

  background-color: #fff;
  border-radius: 12px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    max-width: 546px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const MainCampaign = styled('div')`
  margin-bottom: 40px;

  background-color: #fff;
  border-radius: 12px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    width: max-content;
  }
`;

export const Divider = styled('div')`
  margin-bottom: 40px;
  background-color: #fff;
`;

export const SignUpLink = styled('a')`
  bottom: 40px;
  position: fixed;
  left: 612px;

  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: translateY(-8px);
  }

  @media (max-width: 767.98px) {
    display: none;
  }
`;

export const SignUpLinkCampaign = styled('a')`
  position: sticky;
  margin-left: auto;
  height: 0;
  display: block;
  width: max-content;
  right: 0;
  bottom: 0;
  transform: translate(140px, -180px);

  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: translate(140px, -188px);
  }

  @media (max-width: 767.98px) {
    display: none;
  }
`;
