// @flow

import normalizeBoundingClientRect from './normalizeBoundingClientRect';

export default function(node: HTMLElement) {
  if (!node || !('getBoundingClientRect' in node)) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    };
  }

  return normalizeBoundingClientRect(node, node.getBoundingClientRect());
}
