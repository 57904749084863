// @flow

import * as React from 'react';
import Helmet from 'react-helmet';

type Props = {
  title?: string,
  description?: string,
  children?: any,
};

function Head({ title, description, children }: Props) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {children}
    </Helmet>
  );
}

export default Head;
