// @flow

import gql from 'graphql-tag';

export default gql`
  fragment FacebookAd_ad on FacebookAd {
    action
    id
    url
    title
    description
    descriptions {
      id
      text
    }
    headlines {
      id
      text
    }
    file {
      downloadUrl
      id
      name
      type
      url
      transcodeUrls
      media {
        image {
          height
          src
          width
        }
      }
      thumbnailUrl(public: true)
    }
  }
`;
