import styled from 'styled-components';

export const Icon = styled('div')`
  border-right: 1px solid #f5f5f5;

  display: inline-block;
  width: auto;

  color: #5dc797;

  padding: 10px;
`;
