// @flow

import type { Post_post_attachments_edges } from '../../../shared/graphql.flow';

import * as React from 'react';
import { connect } from 'react-redux';
import { Box } from 'rebass';

import AudioPlayer from '../AudioPlayer';
import ExpandableContainer from '../ExpandableContainer';
import Image from '../Image';
import PostFileAttachment from '../PostFileAttachment';
import PostPhotosGroup from '../PostPhotosGroup';
import VideoPlayer from '../VideoPlayer';

import { openGallery } from '../../actions/gallery';

type Props = {
  attachments: Array<?Post_post_attachments_edges>,
  onPhotoSelect?: () => void,
  openGallery: typeof openGallery,
  postId: string,
};

class PostAttachmentsContainer extends React.PureComponent<Props> {
  renderAttachments() {
    const { attachments, postId } = this.props;

    if (attachments.length > 1) {
      return (
        <PostPhotosGroup
          attachments={attachments}
          count={attachments.length}
          onSelect={this.props.onPhotoSelect}
          postId={postId}
        />
      );
    }

    const attachment = attachments[0].node;
    const { type } = attachment;

    if (type === 'audio') {
      return <AudioPlayer media={attachment} />;
    }

    if (type === 'gif' || type === 'image') {
      const { image } = attachment.media;

      return (
        <ExpandableContainer
          onClick={() => this.props.openGallery(postId, attachment.id)}
        >
          <Box
            css={{
              border: '1px solid rgba(0, 0, 0, 0.065)',
            }}
          >
            <Image
              alt={attachment.name}
              height={(image.height / image.width) * 466}
              src={image.src}
              width={466}
            />
          </Box>
        </ExpandableContainer>
      );
    }

    if (type === 'video') {
      return <VideoPlayer attachment={attachment} />;
    }

    return <PostFileAttachment attachment={attachment} postId={postId} />;
  }

  render() {
    if (this.props.attachments.length === 0) {
      return null;
    }

    return <Box mt={20}>{this.renderAttachments()}</Box>;
  }
}

export default connect(
  null,
  { openGallery }
)(PostAttachmentsContainer);
