import styled from 'styled-components';

export const Wrapper = styled('div')`
  padding-bottom: 50px;
  padding-top: 35px;

  & .nav-link {
    color: #fff !important;
  }

  @media (min-width: 768px) {
    & .nav-item {
      margin-left: 32px;
    }
  }
`;
