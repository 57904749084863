import styled from 'styled-components';

export const Icon = styled('div')`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  font-family: sans-serif;
  font-weight: bold;

  color: #fff;
  user-select: none;
`;
