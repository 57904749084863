// @flow

import * as React from 'react';
import { Box, Flex, Heading } from 'rebass';
import { Relative } from '@rebass/position';

import placeholder from './placeholder.png';
import { ButtonLightRed } from '../buttons';
import Image from '../Image';

type Props = {
  image?: string,
  title: string,
  zipballUrl?: ?string,
};

function CampaignHeader(props: Props) {
  const { image, title, zipballUrl } = props;

  return (
    <Relative mb={-10} px={[20, 40]} top={-20} width={['auto', 546]}>
      <Flex alignItems={[null, 'center']} flexWrap={['wrap', 'nowrap']}>
        <Box
          bg="white"
          mr={20}
          css={{
            border: '2px solid #dbdbdd',
            borderRadius: '50%',
            overflow: 'hidden',
          }}
        >
          {image ? (
            <Image alt="Avatar" height={98} src={image} width={98} />
          ) : (
            <img alt="Avatar" height={98} src={placeholder} width={98} />
          )}
        </Box>

        <Heading
          alignSelf="center"
          as="h1"
          color="#58595b"
          dangerouslySetInnerHTML={{ __html: title }}
          mt={[20, 0]}
        />

        {zipballUrl && (
          <ButtonLightRed
            onClick={() => window.open(zipballUrl)}
            ml="auto"
            mt={[10, 0]}
            style={{ right: '40px' }}
          >
            Download All
          </ButtonLightRed>
        )}
      </Flex>
    </Relative>
  );
}

export default CampaignHeader;
