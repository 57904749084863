// @flow

import gql from 'graphql-tag';

import facebookAdFragment from '../FacebookAd/fragment';
import googleAdFragment from '../GoogleAd/fragment';

export default gql`
  fragment Post_post on Post {
    body
    date
    errorMsg
    id
    isStory
    linkPreview {
      canonicalUrl
      description
      id
      imageUrl
      title
      url
    }
    state
    time
    type
    zipballUrl
    publishAt
    updatedAt
    content {
      body
    }
    tag {
      color
      name
      value
    }
    attachments(first: 20) @include(if: $withAttachments) {
      edges {
        node {
          downloadUrl
          id
          name
          type
          url
          transcodeUrls
          media {
            image {
              height
              src
              width
            }
          }
          thumbnailUrl(public: true)
        }
      }
    }
    campaign {
      account {
        avatarUrl
        id
        name
      }
      brand {
        id
        name
        avatar {
          id
          media {
            image {
              src
            }
          }
        }
      }
    }
    facebookAds(first: 50) @include(if: $withFacebookAds) {
      edges {
        node {
          ...FacebookAd_ad
        }
      }
    }
    googleAd @include(if: $withGoogleAdwords) {
      ...GoogleAd_ad
    }
  }

  ${googleAdFragment}
  ${facebookAdFragment}
`;
