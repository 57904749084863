// @flow

import { css } from 'styled-components';
import * as React from 'react';

import getElementPosition from '../../utils/getElementPosition';

const className = css`
  width: 100%;

  position: fixed;
`;

class PersistScroll extends React.PureComponent<{ children: React.Node }> {
  root: ?HTMLElement = document.getElementById('root');

  componentDidMount() {
    const position = getElementPosition(this.root);

    Object.assign(this.root.style, {
      top: `${position.y}px`,
    });

    this.root.classList.add(className);
  }

  componentWillUnmount() {
    // const top = Math.abs(this.root.offsetTop);

    Object.assign(this.root.style, {
      top: 'auto',
    });

    this.root.classList.remove(className);

    // document.body.scrollTop = top;
    // document.documentElement.scrollTop = top;
  }

  render() {
    return React.Children.only(this.props.children);
  }
}

export default PersistScroll;
