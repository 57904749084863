// @flow

import * as React from 'react';
import { Flex } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Absolute, Relative } from '@rebass/position';

type Props = {
  children: React.Element<any>,
  className?: string,
  onClick?: Function,
  iconType?: String,
};

function ExpandableContainer(props: Props) {

  return (
    <Relative
      className={props.className}
      onClick={props.onClick}
      css={{ cursor: 'zoom-in' }}
    >
      <Absolute right={5} top={5} zIndex={1}>
        <Flex
          alignItems="center"
          bg="#0000005e"
          color="white"
          justifyContent="center"
          width={34}
          css={{ borderRadius: '4px', height: '34px' }}
        >
          <FontAwesomeIcon icon="expand" size="sm" />
        </Flex>
      </Absolute>

      <Absolute right={40} top={5} zIndex={1}>
        <Flex
          alignItems="center"
          bg="#0000005e"
          color="white"
          justifyContent="center"
          width={34}
          css={{ borderRadius: '4px', height: '34px' }}
        >
          <FontAwesomeIcon icon={['fal', props.iconType || 'image']} size="sm" />
        </Flex>
      </Absolute>

      {React.Children.only(props.children)}
    </Relative>
  );
}

export default ExpandableContainer;
