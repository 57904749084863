// @flow

export function openGallery(entityId: string, mediaId: string) {
  return {
    entityId,
    mediaId,
    type: 'SHOW_GALLERY',
  };
}

export function hideGallery() {
  return {
    type: 'HIDE_GALLERY',
  };
}
