// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Text } from 'rebass';
import { Relative } from '@rebass/position';

import ExpandableContainer from '../ExpandableContainer';
import Image from '../Image';

import { openGallery } from '../../actions/gallery';

type Props = {
  attachment: Object,
  openGallery: typeof openGallery,
  postId: string,
};

function PostFileAttachment({ attachment, openGallery, postId }: Props) {
  const { image } = attachment.media;

  if (attachment.name.endsWith('pdf')) {
    return (
      <ExpandableContainer onClick={() => openGallery(postId, attachment.id)}>
        <Image
          alt={attachment.name}
          height={(image.height / image.width) * 466}
          src={image.src}
          width={466}
        />
      </ExpandableContainer>
    );
  }

  return (
    <Relative
      py={3}
      css={{ boxShadow: '0 0 2px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}
    >
      <img alt={attachment.name} src={image.src} width={150} />
      <Text fontWeight="bold" mt={20}>
        {attachment.name}
      </Text>
    </Relative>
  );
}

export default connect(
  null,
  { openGallery }
)(PostFileAttachment);
