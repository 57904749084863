// @flow

import type { GoogleAd_ad } from '../../../shared/graphql.flow';

import * as React from 'react';
import { Box, Link, Card, Flex, Heading, Text } from 'rebass';

type Props = {
  ad: GoogleAd_ad,
  className?: ?string,
};

class GoogleAd extends React.PureComponent<Props> {
  buildUrl(): ?string {
    const { ad } = this.props;

    if (!ad.url) {
      return null;
    }

    return [ad.url, ad.path1, ad.path2]
      .filter(Boolean)
      .join(`/`)
      .replace(/^https?:\/\//i, '');
  }

  getHeadline() {
    const { ad } = this.props;

    return [ad.headline1, ad.headline2, ad.headline3]
      .filter(Boolean)
      .join(` | `);
  }

  render() {
    const { ad, className } = this.props;
    const url = this.buildUrl();

    return (
      <Card borderRadius={2} boxShadow="small" className={className} p={3}>
        <Flex>
          <Text color="#202124" fontSize={1} fontWeight="bold">
            Ad
          </Text>

          <Box mx={2}>·</Box>

          {url && (
            <Text
              as="cite"
              color="#3c4043"
              fontSize={1}
              css={{ fontStyle: 'normal' }}
            >
              {url}
            </Text>
          )}
        </Flex>

        <Heading as="h3" fontSize={20} fontWeight="normal" mt={1}>
          <Link
            color="#1a0dab"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {this.getHeadline()}
          </Link>
        </Heading>

        <Text color="#545454" fontSize={13} lineHeight="18px" mt={1}>
          {ad.description1} {ad.description2}
        </Text>
      </Card>
    );
  }
}

export default GoogleAd;
